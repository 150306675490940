import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import Footer from "../components/footer"
import Navi from "../components/navi"
import HeroImage from "../components/heroImage"
import TextBlock from "../components/textBlock"
import '../styles/index.scss';
import liliimage from "../images/lili.jpg"
import { Link } from "gatsby"


function Index() {
  return (
    <main>
      <Helmet>
        <title>Black Unicorn Wedding Photography</title>
        <meta name="title" content="Black Unicorn Wedding Photography | Hochzeitsfotografie - Über Mich" />
        <meta name="description" content="Hochzeitsfotografie in Rastatt und Berlin. ✔individuell ✔besonders ✔qualitativ " />
        <meta name="image" content="/src/images/favicon.jpg"/>
        <meta property="og:image" content="/src/images/favicon.jpg"/>
        <link rel="canonical" href="https://www.blackunicornwedding.studio/" />
      </Helmet>
      <Layout>
        <Navi></Navi>
        <HeroImage link={{text: "hey! …", url:"/"}} heroimage={liliimage}></HeroImage>
        <TextBlock headline={"ich bin's Lili."} text={"Ursprünglich aus Rastatt in der Nähe des Schwarzwalds, habe ich 2017 die Leidenschaft für die Fotografie entdeckt. Eigentlich war ich zunächst von Landschaften fasziniert, bis ich durch meine Erfahrungen auf Events merkte, dass es mir unfassbar viel Spass macht, Menschen und ihre Emotionen zu begleiten. Seitedm fotografiere ich Events sowie Hochzeiten. Ich freue mich, dass du auf meine Seite gefunden hast. 🙋‍♀️ Natürlich könnte ich jetzt auch noch ein paar beeindruckende Zahlen auspacken, die meine Erfahrungen als Hochzeitsfotografin greifbar machen, aber schaue dich doch einfach besser hier um. Wenn dir mein Bildstil und meine ungestellte Art Momente einzufangen gefällt, schreib mir gerne, ich erzähle dir auf jeden Fall gerne mehr."}></TextBlock>
        <Link className="link link--mneme" to="/#contact">Schreib mir!</Link>
        <Footer></Footer>
        </Layout>
    </main>
  );
}

export default Index;


