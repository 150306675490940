// extracted by mini-css-extract-plugin
export var error = "heroImage-module--error--3-hFC";
export var h1 = "heroImage-module--h1--f4Blz";
export var imageLink = "heroImage-module--imageLink--3BaqW";
export var contentItem = "heroImage-module--content__item--H0Uhv";
export var link = "heroImage-module--link--3YOdC";
export var linkMneme = "heroImage-module--link--mneme--2Tcyt";
export var coverUp = "heroImage-module--coverUp--3xiZZ";
export var heroImage = "heroImage-module--heroImage--3qlCf";
export var contentitem = "heroImage-module--contentitem--2gQfY";
export var linkmneme = "heroImage-module--linkmneme--2YJTU";