import React from "react"
import { Link } from "gatsby"
import * as styles from './textBlock.module.scss'

export default function textBlock ({ headline1, headline, text, links, size }) {

    if(size==70){
        return <div className={styles.quarter}>
        { typeof headline1 !== 'undefined' && headline1.length !== 0 && <h1>{headline1}</h1> }
        { typeof headline !== 'undefined' && headline.length !== 0 && <h2>{headline}</h2> }
        <p>{text}</p>
        { typeof links !== 'undefined' && links.map((link) => <Link className="link link--mneme" to={link.path}>{link.text}</Link> ) }
    </div>
    }

    else{
        return <div>
        { typeof headline1 !== 'undefined' && headline1.length !== 0 && <h1>{headline1}</h1> }
        { typeof headline !== 'undefined' && headline.length !== 0 && <h2>{headline}</h2> }
        <p>{text}</p>
        { typeof links !== 'undefined' && links.map((link) => <Link className="link link--mneme" to={link.path}>{link.text}</Link> ) }
    </div>
    }


    
}
    