import React from "react"
import * as styles from "./heroImage.module.scss"
import { Link } from "gatsby"
import '../styles/index.scss';



export default function heroImage ({heroimage,link}) {
    console.log(link)
    return <div className={styles.heroImage}>
        <div className={styles.contentitem}>
            <Link className={[styles.link,styles.linkmneme].join(' ')} to={link.url}>{link.text}</Link>
        </div>
        <img src={heroimage} alt="heroimage blackweddings photography"></img>
    </div>
}

